import config from "config";

const urls = {
    register: `${config.backend_endpoint}auth/register/`,
    login: `${config.backend_endpoint}auth/login/`,
    sendOTP: `${config.backend_endpoint}auth/otp/send/`,
    sendLoginOTP: `${config.backend_endpoint}auth/login/otp/send/`,
    reSendLoginOTP: `${config.backend_endpoint}auth/login/otp/resend/`,
    verifyOTP: `${config.backend_endpoint}auth/otp/verify/`,
    forgotPassword: `${config.backend_endpoint}auth/forgot/password/`,

    user: `${config.backend_endpoint}user/`,
    walletBalance: `${config.backend_endpoint}user/wallet/`,
    getUserVehicle: `${config.backend_endpoint}user/vehicles/`,
    saveVehicleModel: `${config.backend_endpoint}user/vehicle/save/`,
    updateUserDetails: `${config.backend_endpoint}user/account/update/`,
    deleteVehicle: `${config.backend_endpoint}user/vehicle/delete/`,

    vehicleMakers: `${config.backend_endpoint}vehicle/make/`,
    vehicleModels: `${config.backend_endpoint}vehicle/model/`,

    walletRecharge: `${config.backend_endpoint}user/wallet/recharge/`,
    livePaidChargingSession: `${config.backend_endpoint}user/live/paid/session/`,
    livePenaltySession: `${config.backend_endpoint}user/live/penalty/session/`,
    walletRechargeStatus: `${config.backend_endpoint}user/wallet/recharge/status/`,

    initPayment: `${config.backend_domain}/init/payment/`,

    chargerList: `${config.backend_endpoint}charger/list/`,
    chargerDetails: `${config.backend_endpoint}charger/details/`,
    chargerStart: `${config.backend_endpoint}charger/start/`,
    chargerStop: `${config.backend_endpoint}charger/stop/`,
    chargerStatus: `${config.backend_endpoint}charger/status/`,

    myUsage: `${config.backend_endpoint}user/charger/usage/`,
    myHistory: `${config.backend_endpoint}user/charger/history/`,

    mapPlotFacility: `${config.backend_endpoint}map/plot/facility/`,
    mapFacilityChargers: `${config.backend_endpoint}map/facility/`,

    facilityList: `${config.backend_endpoint}facility/list/`,

    placeSearch: `${config.backend_endpoint}map/search/`,

    deleteAccount: `${config.backend_endpoint}user/account/delete/`,

    getActiveCountries: `${config.backend_endpoint}active/countries/`,

    getGeoCountryCode: `${config.backend_endpoint}map/countrycode/`,
    getAddress: `${config.backend_endpoint}user/address/`,
    saveAddress: `${config.backend_endpoint}user/address/add/`,
    downloadInvoice: `${config.backend_endpoint}download/invoice/session/`,

    stopPenalty: `${config.backend_endpoint}booking/stoppenalty/`,
    payPenalty: `${config.backend_endpoint}booking/paypenalty/`,

    couponList: `${config.backend_endpoint}user/coupon/list/`,
    applyCoupon: `${config.backend_endpoint}user/apply/coupon/`,

    placeOrder: `${config.backend_endpoint}rfid/place/order/`,
    trackingOrder: `${config.backend_endpoint}rfid/order/tracking/`

}
export default urls;