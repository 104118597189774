import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
// material-ui
import { useTheme } from '@mui/material/styles';
import { Grid } from '@mui/material';

// project import
import DrawerHeaderStyled from './DrawerHeaderStyled';
// import Logo from 'components/Logo';
import { Logo } from 'components/icons';
import { Language } from 'language/index';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';


// ==============================|| DRAWER HEADER ||============================== //

const DrawerHeader = ({ open }) => {
	const user = useSelector(state => state.user.data)
	const theme = useTheme();

	const [firstNameInitial, setFirstNameInitial] = useState('')
	const [lastNameInitial, setLastNameInitial] = useState('')

	useEffect(() => {
		if (user) {
			const fullName = user.name;
			const nameParts = fullName?.split(' ');
			setFirstNameInitial(nameParts?.[0] ? nameParts[0][0] : '')
			setLastNameInitial(nameParts?.[1] ? nameParts[1][0] : '')
		}
	}, [user]);

	return (
		<DrawerHeaderStyled theme={theme} open={open}>
			{user.name && (user.name !== '' || user.name !== null) ? (
				<Grid container>
					<Grid item xs={1}></Grid>
					<Grid item xs={2} display="flex" justifyContent="right" alignItems="center">
						{
							user.picture === '' ? (
								<img alt={firstNameInitial + lastNameInitial} src={user.picture} style={{ borderRadius: 50, height: 60 }} />
							) : (
								<span style={{ fontSize: 18, fontWeight: 'bold', borderRadius: "50%", border: "1px solid", padding: "8px 15px" }}>{firstNameInitial + lastNameInitial}</span>
							)
						}
					</Grid>
					<Grid item xs={1}></Grid>
					<Grid item xs={8} display="flex" justifyContent="left" alignItems="center">
						<Grid container direction="column">
							<Grid item justifyContent="left" alignItems="bottom">
								<span style={{ fontSize: 18, fontWeight: 'bold' }}>{user.name}</span>
							</Grid>
							<Grid item justifyContent="left" alignItems="top">
								<Link to='my-account'><span style={{ fontSize: 12, fontWeight: 'light' }} color='secondary'>{Language.label.view_profile}</span></Link>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			) : (<>
				<Grid container>
					<Grid item xs={1}></Grid>
					<Grid item xs={10} display="flex" justifyContent="right" alignItems="center">
						{Logo()}
						{/* <Logo /> */}
					</Grid>
					<Grid item xs={1}></Grid>
				</Grid>
				{/* Removed in new version */}
				{/* <Stack direction="row" spacing={1} alignItems="center">
					<Logo />
					<Chip
						label={process.env.REACT_APP_VERSION}
						size="small"
						sx={{ height: 16, '& .MuiChip-label': { fontSize: '0.625rem', py: 0.25 } }}
					/>
				</Stack>
        <Typography variant="h5" component="div" paddingLeft={3}>
          Terra Charge India
        </Typography> */}
			</>
			)}
			{/* <Stack direction="row" spacing={1} alignItems="center">
        <Logo />
        <Chip
          label={process.env.REACT_APP_VERSION}
          size="small"
          sx={{ height: 16, '& .MuiChip-label': { fontSize: '0.625rem', py: 0.25 } }}
        />
      </Stack> */}
			{/* Removed in new version */}
		</DrawerHeaderStyled>
	);
};

DrawerHeader.propTypes = {
	open: PropTypes.bool
};

export default DrawerHeader;
