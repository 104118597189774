const Language = {
	th: {
		label: {
			view_profile: 'ดูประวัติ',
			list: 'สถานี',
			enter_mobile_number: 'กรอกหมายเลขโทรศัพท์ของคุณ 📱',
			enter_your_name: 'สวัสดี, คุณชื่ออะไร? 👋',
			enter_your_email: 'กรอกอีเมลของคุณ 📧',
			email_message: 'ต้องการอีเมลสำหรับจัดส่งใบเสร็จ',
			confirm_country_code_enter_mobile_number: 'กรุณายืนยันรหัสประเทศของคุณ และกรอกหมายเลขโทรศัพท์ของคุณ',
			mobile_verification: 'ยืนยันหมายเลขโทรศัพท์',
			confirm_mobile_verification_code: 'กรุณากรอกรหัส 4 หลัก ที่ส่งไปที่หมายเลขโทรศัพท์ของคุณเพื่อการยืนยัน',
			connectors: 'หัวชาร์จ:',
			connector_type: 'ประเภทหัวชาร์จ',
			units_consumed: 'หน่วยที่ใช้ไป',
			amount_spent: 'จำนวนเงินที่ใช้ไป',
			co2_saved: 'ช่วยลด Co2',
			name: 'ชื่อ',
			mobile: 'หมายเลขโทรศัพท์',
			email: 'อีเมล',
			vehicle_number: "หมายเลขรถ",
			battery_started_at: 'แบตเตอรี่เริ่มต้นที่',
			remperreq: 'Rem./Req.',
			time: 'เวลา',
			amt: 'ราคา',
			elapsed_time: 'เวลาที่ใช้',
			remaining_time: 'เวลาที่เหลือ',
			remaining_amount: 'จำนวนเงินที่เหลือ',
			remaining_unit: 'หน่วยที่เหลือ',
			charging_summary: 'สรุปการชาร์จ',
			charger_id: 'ID เครื่องชาร์จ',
			start_time: 'เวลาเริ่มต้น',
			end_time: 'เวลาสิ้นสุด',
			charge_start_percent: '% ที่เริ่มการชาร์จ',
			charge_end_percent: '% ที่สิ้นสุดการชาร์จ',
			gun: 'หัวชาร์จ',
			pricing: 'ช่วงราคา',
			end_reason: 'เหตุผลที่สิ้นสุด',
			charging_status: 'สถานะการชาร์จ',
			connected: 'เชื่อมต่อแล้ว',
			charging: 'กำลังชาร์จ',
			battery_information: 'ข้อมูลแบตเตอรี่',
			consumed: 'จำนวนที่ใช้',
			battery: 'แบตเตอรี่',
			amount: 'จำนวน',
			total: 'ทั้งหมด',
			free: 'ฟรี',
			private: 'ส่วนตัว',
			capacity: 'ความจุ',
			price: 'จำนวนเงิน',
			unit: 'หน่วย',
			set_time: 'เลือกเวลา',
			set_unit: 'ตั้งค่าหน่วย',
			set_price: 'เลือกจำนวนเงิน',
			wallet_balance: 'ยอดคงเหลือในวอลเล็ท',
			wallet_balance_kwh: 'ยอดคงเหลือ kWh',
			available_balance: 'ยอดเงินคงเหลือ',
			estimate: 'โดยประมาณ',
			estimate_kwh: 'ประมาณการ kWh',
			enter_valid_phone_number: 'กรอกหมายเลขโทรศัพท์ให้ถูกต้อง',
			enter_valid_otp: 'กรอกรหัส OTP ให้ถูกต้อง',
			phone_number: 'หมายเลขโทรศัพท์',
			country: 'ประเทศ',
			enter_otp: 'กรอกรหัส OTP',
			firstname: 'ชื่อจริง',
			email_address: 'อีเมล',
			password: 'รหัสผ่าน',
			otp: 'รหัส OTP',
			keep_me_sign_in: 'จำข้อมูลไว้',
			india: 'อินเดีย',
			japan: 'ญี่ปุ่น',
			thailand: 'ไทย',
			settings: "การตั้งค่า",
			change_language: "เปลี่ยนภาษา",
			select_language: "เลือกภาษา",
			full_charge: "ชาร์จเต็ม",
			indonesia: "อินโดนีเซีย",
			charger_status: "สถานะเครื่องชาร์จ",
			faulted: "มีข้อผิดพลาด",
			finishing: "กำลังเสร็จสิ้น",
			charging_fee: 'ค่าชาร์จไฟฟ้า',
			booking_overtime: 'เกินเวลา',
			overtime_fee: 'ค่าชาร์จเกินเวลา',
			your_booking_overtime: 'การชาร์จเกินเวลา',
			invoice_address: 'ที่อยู่ใบกำกับภาษี',
			otp_login: "คลิกที่นี่ : ในกรณีที่ต้องการเข้าสู่ระบบด้วย OTP",
			password_login: "คลิกที่นี่ : ในกรณีที่ต้องการเข้าสู่ระบบด้วยรหัสผ่าน",
			order_placed: "การสั่งซื้อของคุณได้รับการยืนยันแล้วและจะถูกจัดส่งในไม่ช้า",
			order_id: "รหัสคำสั่งซื้อ",
			order_date: "วันที่สั่งซื้อ",
			estimate_delivery: "การจัดส่งโดยประมาณ",
			delivery_status: "สถานะการจัดส่ง",
			tracking_id: "รหัสติดตาม",
			rfid: "อาร์เอฟไอดี",
			terra_charge: "เทอร์ร่าชาร์จ",
			grow_together: "มาร่วมเติบโตไปด้วยกัน",
			order_rfid: "สั่งซื้อ RFID",
			claim_rfid: "รับบัตร RFID ฟรีของคุณ",
			coupon:  "คูปอง",
			coupon_discount: "คูปองส่วนลด"
		},
		placeholder: {
			mobile_number: 'หมายเลขโทรศัพท์',
			enter_full_name: 'กรอกชื่อ-นามสกุล',
			enter_email: 'กรอกอีเมล',
			country_code: 'รหัสประเทศ',
			otp: 'รหัสผ่านใช้ครั้งเดียว',
			resend_otp: 'ส่งอีกครั้งใน {seconds} วินาที',
			phone_number: 'กรอกหมายเลขโทรศัพท์ 10 หลัก',
			enter_password: 'ใส่รหัสผ่าน',
			enter_otp: 'กรอกรหัส OTP',
			firstname: 'ชื่อ',
			email_address: 'อีเมล'
		},
		heading: {
			amount_in_wallet: 'จำนวนเงินในวอลเล็ทของคุณ :',
			balance: 'Balance',
			balance_kwh: 'kWh Balance',
			last_transaction: 'การทำธุรกรรมครั้งล่าสุด',
			terms_of_service: 'เงื่อนไขการให้บริการ',
			privacy_policy: 'นโยบายความเป็นส่วนตัว',
			please_type_the: 'กรุณากรอกข้อมูล',
			add_vehicle: 'เพิ่มรถยนต์ของท่าน',
			my_usage: 'การใช้งานของฉัน',
			charging_sessions: 'เซสชันการชาร์จ',
			upcoming: 'กำลังมาถึง',
			past: 'ดำเนินการแล้ว',
			cancelled: 'ยกเลิก',
			facing_issue_charging: 'เกิดข้อผิดพลาดขณะชาร์จ?',
			terra_wallet: 'เทอร์ร่า วอลเล็ท',
			estmate_approx: 'โดยประมาณ',
			already_have_account: 'มีบัญชีอยู่แล้ว?',
			signup: 'สมัครสมาชิก',
			dont_have_account: 'ยังไม่มีบัญชี?',
			login: 'เข้าสู่ระบบ',
			forgot_password: 'ลืมรหัสผ่าน',
			back_to_login: 'กลับไปที่เข้าสู่ระบบ',
			home: 'หน้าหลัก',
			signup_with: 'ลงทะเบียนด้วย',
			login_with: 'เข้าสู่ระบบด้วย',
			dashboard: 'แดชบอร์ด',
			profile: 'โปรไฟล์',
			wallet: 'วอลเล็ท',
			my_vehicles: 'รถยนต์ของฉัน',
			charging_history: 'ประวัติการชาร์จ',
			contact_us: 'ติดต่อเรา',
			logout: 'ออกจากระบบ',
			map: 'แผนที่',
			history: 'ประวัติ',
			terra_motors_country: 'เทอร์ร่าชาร์จ ไทยแลนด์',
			unpaid_fees: 'รายการรอชำระ'
			,available_coupons:'คูปองที่มีอยู่'
		},
		list: {
			charging_statuses: {
				charging: 'กำลังชาร์จ',
				abandoned: 'ถูกละทิ้ง',
				available: 'พร้อมใช้งาน',
				completed: 'เสร็จสิ้น',
				initiated: 'กำลังเริ่มต้น',
				preparing: 'เตรียมพร้อม'
			}
		},
		button: {
			continue: 'ต่อไป',
			save: 'บันทึก',
			topup: 'เติมเงิน',
			add_money: 'เติมเงิน',
			map_view: 'มุมมองแผนที่',
			type_device_id: 'ID ประเภทอุปกรณ์',
			activate: 'พร้อมใช้งาน',
			resend_otp: 'ส่งรหัส OTP อีกครั้ง',
			password: 'รหัสผ่าน',
			cancel: 'ยกเลิก',
			update: 'อัปเดต',
			logout: 'ออกจากระบบ',
			cancel_goback: 'ยกเลิก, ย้อนกลับ',
			confirm_delete: 'ยืนยันการลบ',
			continue_on_app: 'ยืนยันและต้องการดำเนินการต่อด้วย Web App',
			click_to_call: 'คลิกที่นี่เพื่อโทรหาเรา',
			or_dial: 'หรือโทร',
			stop_charging: 'หยุดชาร์จ',
			wallet_link: 'วอลเล็ท',
			next_disabled: 'สามารถไปต่อได้เมื่อเลือกเวลา หรือ ราคา',
			next: 'ถัดไป',
			start_charging: 'เริ่มชาร์จ',
			verify_otp: 'ตรวจสอบรหัส OTP',
			send_otp: 'ส่งรหัส OTP',
			send_password: 'ส่งรหัสผ่าน',
			create_account: 'สร้างบัญชี',
			login: 'เข้าสู่ระบบ',
			credit: 'บัตรเครดิต/เดบิต',
			qr: 'พร้อมเพย์',
			select: 'เลือก',
			confirm_country_change: 'ยืนยันการเปลี่ยนแปลง',
			enter_new_address: 'เพิ่มที่อยู่',
			add_billing_address: 'กรุณาเพิ่มที่อยู่ใหม่',
			download_invoice: 'ดาวน์โหลดใบกำกับภาษี',
			generate_invoice: 'สร้างใบแจ้งหนี้',
			scan_to_checkout: 'สแกน QR เพื่อจบการชาร์จ',
			make_payment: 'ชำระเงิน',
			apply_now: "ลงทะเบียนเลย",
			back_to_home: "กลับไปที่หน้าหลัก",
			track_shipment: "ติดตามการจัดส่ง",
			place_order: "สั่งซื้อ",
			already_requested: "ได้ร้องขอไปแล้ว",
			apply_coupon: "ใช้คูปอง"
		},
		generic: {
			or: 'หรือ',
			and: 'และ',
			continue_with_google: 'ดำเนินการต่อด้วย Google',
			agree_terms_conditions: 'เมื่อดำเนินการต่อ จะถือว่าคุณยอมรับ',
			terms_and_conditions: 'ข้อตกลงและเงื่อนไข',
			privacy_policy: 'นโยบายความเป็นส่วนตัว',
			currency_char: '฿',
			device_id: 'ID อุปกรณ์',
			month: 'เดือน',
			week: 'สัปดาห์',
			months: {
				jan: 'ม.ค.',
				feb: 'ก.พ. ',
				mar: 'มี.ค.',
				apr: 'เม.ย.',
				may: 'พ.ค.',
				jun: 'มิ.ย.',
				jul: 'ก.ค.',
				aug: 'ส.ค.',
				sep: 'ก.ย.',
				oct: 'ต.ค.',
				nov: 'พ.ย.',
				dec: 'ธ.ค.',
			},
			days: {
				mon: 'จ.',
				tue: 'อ.',
				wed: 'พ.',
				thu: 'พฤ.',
				fri: 'ศ.',
				sat: 'ส.',
				sun: 'อา.'
			},
			open24in7: 'เปิดตลอด 24/7',
			download_app: 'ดาวน์โหลดแอพ Terra Charge',
			phone_no: '9902179575',
			available: 'คงเหลือ',
			dateandtime: 'วันที่ & เวลา',
			amount: 'จำนวน',
			kwh: 'กิโลวัตต์ชั่วโมง',
			kw: 'กิโลวัตต์',
			hour: 'ชั่วโมง',
			minute: 'นาที',
			hours: 'ชั่วโมง',
			mins: 'นาที',
			seconds: 'วินาที',
			update_available:"มีการอัปเดตแอป"
		},
		message: {
			no_internet_wrapper: 'ไม่พบสัญญาณอินเทอร์เน็ต กรุณาลองใหม่อีกครั้ง',
			not_valid_qr_code: 'QR Code ไม่ถูกต้อง',
			you_dont_have_vehicles: 'คุณยังไม่มียานพาหนะที่บันทึกเอาไว้',
			you_dont_have_charging: 'คุณยังไม่มีรายการชาร์จ',
			loading: 'กำลังโหลด.....',
			no_charging_records: 'ไม่พบบันทึกการชาร์จ',
			nothing_found_in_upcoming: 'ไม่มีรายการใน "กำลังมาถึง"',
			nothing_found_in_past: 'ไม่มีรายการใน "ดำเนินการแล้ว"',
			nothing_found_in_cancelled: 'ไม่มีรายการใน "ยกเลิก"',
			are_you_sure_delete_account: 'คุณแน่ใจที่จะลบบัญชีผู้ใช้งาน?',
			delete_acccount_warning: 'การลบบัญชีนั้น คุณจะต้องยืนยันว่าคุณจะไม่สามารถใช้งานบัญชีนี้ได้อีกต่อไป และข้อมูลต่างๆ จะถูกตั้งเวลาลบโดยอัตโนมัติใน 7 วัน',
			error_boundary: 'พบข้อผิดพลาด',
			something_went_wrong: 'มีบางอย่างผิดพลาด',
			stopping: 'กำลังหยุดการทำงาน.....',
			use_this_slider_set_session: 'ใช้แถบเลื่อนนี้เพื่อตั้งเซสชั่นการชาร์จ โดยอิงตามราคาหรือเวลา',
			use_this_slider_time_amount: 'ใช้แถบเลื่อนนี้เพื่อเลือกเวลาหรือจำนวนที่จะให้เซสชั่นการชาร์จทำงานต่อไป การชาร์จะจบลงเมื่อถึงเวลาและจำนวนที่ตั้งเอาไว้',
			charger_not_found: 'ไม่พบเครื่องชาร์จ',
			loging_out: 'กำลังออกจากระบบ.....',
			otp_cannot_empty: 'OTP ไม่สามารถเป็นค่าว่างได้',
			resend_otp_in: 'สั่งใหม่อีกครั้งใน',
			registration_successful: 'การสมัครสมาชิกสำเร็จ กรุณายืนยันหมายเลขโทรศัพท์',
			verify_inputs: 'กรุณากรอกข้อมูลให้ครบ',
			by_siging_up_agree: 'โดยการสมัครสมาชิกนั้น คุณจะต้องทำข้อตกลง',
			redirecting: 'กำลังเปลี่ยนหน้า...',
			all_right_reserve: 'สงวนลิขสิทธิ์',
			confirm_change_country: 'คำเตือน!!! การเปลี่ยนประเทศ',
			change_country_warning: 'คุณอาจไม่สามารถเข้าสู่ระบบด้วย OTP ได้หากคุณเปลี่ยนประเทศ คุณอาจไม่ได้รับ OTP',
			penalty_is_running: 'มีการคำนวณค่าปรับ',
			please_settle_the_fines: 'กรุณาชำระรายการนี้ก่อนทำรายการถัดไป',
			outstanding_amount: 'ตรวจพบรายการชำระคงค้างในรายการก่อนหน้า',
			charger_is_starting_up: 'การชาร์จกำลังเริ่มต้นขึ้น ...',
			charge_info_group_1_1: 'กรุณา',
			charge_info_group_1_2: 'ล็อก',
			charge_info_group_1_3: 'รถของคุณและ',
			charge_info_group_1_4: 'เชื่อมต่อ',
			charge_info_group_1_5: 'สายชาร์จ',
			please_dont_close_the_app: 'กรุณาอย่าปิดแอพพลิเคชั่น',
			get_ready: 'เตรียมพร้อม!',
			charge_info_group_2_1: 'กรุณาอย่าลืมกลับมาที่รถ',
			charge_info_group_2_2: 'ให้ตรงเวลาเพื่อหลีกเลี่ยงค่าปรับจำนวน',
			please_pay: 'กรุณาชำระเงินให้เสร็จสิ้นหลังจากการชาร์จ',
			session_is_overtime: 'ชาร์จเกินกำหนดเวลา !',
			additional_overtime: 'ราคาการชาร์จเกินเวลา',
			balance_not_enough: 'จำนวนเงินไม่เพียงพอ กรุณาเติมเงิน',
			no_charger_found: "ไม่พบเครื่องชาร์จ",
			unable_to_stop_charging: "คุณไม่สามารถหยุดการชาร์จก่อน 1 นาที กรุณาลองใหม่อีกครั้ง",
			select_address_to_download_invoice: "โปรดเลือกที่อยู่เพื่อดาวน์โหลดใบแจ้งหนี้",
			no_coupons_found: "ไม่พบคูปอง",
			select_address_to_request_rfid: "โปรดเลือกที่อยู่เพื่อขอรับ RFID" ,
			add_to_wallet: 'เพิ่ม */amount/* ลงในกระเป๋าเงินของคุณ' ,
			applied: "สมัครแล้ว",
			coupon_apply_after_session_note: "หมายเหตุ :- คูปองจะถูกใช้เมื่อเซสชันการชาร์จสิ้นสุดลงเท่านั้น"
		}
	}
};

export default Language;
