// project import
// import NavCard from './NavCard';
import Navigation from './Navigation';
import SimpleBar from 'components/third-party/SimpleBar';
import { Grid, Typography } from '@mui/material';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useDispatch } from 'react-redux';
import { isBrowser, downloadApp } from 'common';
import { Logo } from 'components/icons'
import React, { useEffect } from 'react';
import { Language } from 'language';
import ChangeLanguage from 'language/ChangeLanguage';
// ==============================|| DRAWER CONTENT ||============================== //

const DrawerContent = () => {
	const theme = useTheme();
	const isRunningInBrowser = isBrowser();

	useEffect(() => {}, []);
	return (
		<SimpleBar
			sx={{
				'& .simplebar-content': {
					display: 'flex',
					flexDirection: 'column'
				},
				height: '100%'
			}}
		>
			<Navigation />
			<Grid container spacing={0}>
				<Grid item xs={12} style={{ borderTop: '1px solid #EAEBEE', paddingTop: '15px' }}>
					<Typography variant="body1" gutterBottom style={{ paddingLeft: '28px' }}>
						<Typography variant="caption">{Language.label.settings}</Typography>
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<ChangeLanguage />
				</Grid>
			</Grid>
			{isRunningInBrowser && downloadApp(30)}
			<Grid container style={{ position: 'absolute', bottom: 10, paddingLeft: 20 }}>
				<Grid item xs={12}>
					{Logo()}
				</Grid>
			</Grid>
			{/* Removed in new version */}
			{/* <Grid container sx={{ position: 'absolute', bottom: 40 }}>
				<Grid item xs={1}></Grid>
				<Grid item xs={5}>
					<Typography variant="caption">
						<Link
							component={RouterLink}
							to="/privacy-policy"
							underline="hover"
							onClick={() => {
								if (matchDownMD) dispatch(openDrawer({ drawerOpen: false }));
							}}
						>
							Privacy Policy
						</Link>
					</Typography>
				</Grid>
				<Grid item xs={5}>
					<Typography variant="caption">
						<Link
							component={RouterLink}
							to="/terms-of-service"
							underline="hover"
							onClick={() => {
								if (matchDownMD) dispatch(openDrawer({ drawerOpen: false }));
							}}
						>
							Terms of Service
						</Link>
					</Typography>
				</Grid>
				<Grid item xs={1}></Grid>
			</Grid>
			<Grid container sx={{ position: 'absolute', bottom: 20 }}>
				<Grid item xs={1}></Grid>
				<Grid item xs={5}>
					<Typography variant="caption">
						<Link
							component={RouterLink}
							to="/contact-us"
							underline="hover"
							onClick={() => {
								if (matchDownMD) dispatch(openDrawer({ drawerOpen: false }));
							}}
						>
							Contact Us
						</Link>
					</Typography>
				</Grid>
				<Grid item xs={5}>
					<Typography variant="caption">
						<Link
							component={RouterLink}
							to="/payment-charges"
							underline="hover"
							onClick={() => {
								if (matchDownMD) dispatch(openDrawer({ drawerOpen: false }));
							}}
						>
							Payment Charges
						</Link>
					</Typography>
				</Grid>
				<Grid item xs={1}></Grid>
			</Grid>
			<Grid container sx={{ position: 'absolute', bottom: 0 }}>
				<Grid item xs={1}></Grid>
				<Grid item xs={7}>
					<Typography variant="caption">
						<Link
							component={RouterLink}
							to="/cancellation-refund"
							underline="hover"
							onClick={() => {
								if (matchDownMD) dispatch(openDrawer({ drawerOpen: false }));
							}}
						>
							Cancellation & Refund
						</Link>
					</Typography>
				</Grid>
				<Grid item xs={4}>
					<Typography variant="caption">
						<Link
							component={RouterLink}
							to="/about-us"
							underline="hover"
							onClick={() => {
								if (matchDownMD) dispatch(openDrawer({ drawerOpen: false }));
							}}
						>
							About
						</Link>
					</Typography>
				</Grid>
			</Grid> */}
			{/* Removed in new version */}
		</SimpleBar>
	);
};

export default DrawerContent;
