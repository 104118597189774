const Language = {
	en: {
		label: {
			view_profile: 'View Profile',
			list: 'Stations',
			enter_mobile_number: 'Enter your mobile number 📱',
			enter_your_name: 'Hi, What\'s your name? 👋',
			enter_your_email: 'Enter your email address 📧',
			email_message: 'Email address required for business invoicing',
			confirm_country_code_enter_mobile_number: 'Please confirm your country code and enter your mobile number.',
			mobile_verification: 'Mobile verification',
			confirm_mobile_verification_code: "Enter the 4 digit code we've sent to your mobile number for verification.",
			connectors: 'Connectors:',
			connector_type: 'Connector Type',
			units_consumed: 'Units Consumed',
			amount_spent: 'Amount Spent',
			co2_saved: 'Co2 Saved',
			name: 'Name',
			mobile: 'Mobile',
			email: 'Email',
			vehicle_number:"Vehicle Number",
			battery_started_at: 'Battery Started At',
			remperreq: 'Rem./Req.',
			time: 'Time',
			amt: 'Amt.',
			elapsed_time: 'Elapsed Time',
			remaining_time: 'Remaining Time',
			remaining_amount: 'Remaining Amount',
			remaining_unit: 'Remaining Unit',
			charging_summary: 'Charging Summary',
			charger_id: 'Charger ID',
			start_time: 'Start Time',
			end_time: 'End Time',
			charge_start_percent: 'Charge Start %',
			charge_end_percent: 'Charge End %',
			gun: 'Gun',
			pricing: 'Pricing',
			end_reason: 'End Reason',
			charging_status: 'Charging Status',
			connected: 'Connected',
			charging: 'Charging',
			battery_information: 'Charging Information',
			consumed: 'Consumed',
			battery: 'Battery',
			amount: 'Amount',
			total: 'Total',
			free: 'Free',
			private: 'Private',
			capacity: 'Capacity',
			price: 'Price',
			unit: 'Unit',
			set_time: 'Set Time',
			set_unit: 'Set Unit',
			set_price: 'Set Price',
			wallet_balance: 'Wallet balance',
			wallet_balance_kwh: 'kWh Balance',
			available_balance: 'Available',
			estimate: 'Estimated Price',
			estimate_kwh: 'Estimated kWh',
			enter_valid_phone_number: 'Enter Valid Phone Number',
			enter_valid_otp: 'Enter Valid OTP',
			phone_number: 'Phone Number',
			country: 'Country',
			enter_otp: 'Enter OTP',
			firstname: 'First Name',
			email_address: 'Email Address',
			password: 'Password',
			otp: 'OTP',
			keep_me_sign_in: 'Keep me sign in',
			india: 'India',
			japan: 'Japan',
			thailand: 'Thailand',
			settings: "Settings",
			change_language: "Change Language",
			select_language: "Select your language",
			full_charge: "Full Charge",
			indonesia: "Indonesia",
			charger_status: "Charger Status",
			faulted: "Faulted",
			finishing: "Finishing",
			charging_fee: 'Charging Fee',
			booking_overtime: 'Booking Overtime',
			overtime_fee: 'Overtime Fee',
			your_booking_overtime: 'Your booking over time',
			invoice_address: 'Address',
			otp_login:"Click here : In case of otp login",
			password_login:"Click here : In case of password login",
			initial_balance:"Initial Balance",
			final_balance:"Final Balance",
			order_placed:"Your order has been confirmed and will be delivered shortly",
			order_id:"Order id",
			order_date:"Order date",
			estimate_delivery:"Estimated delivery",
			delivery_status:"Delivery Status",
			tracking_id:"Tracking id",
			rfid:"RFID",
			terra_charge:" Terra Charge",
			grow_together:"Let's grow together",
			order_rfid:"Order for RFID",
			claim_rfid:"Claim your free RFID Card",
			coupon:  "Coupon",
			coupon_discount: "Coupon Discount"
		},
		placeholder: {
			mobile_number: 'Mobile Number',
			enter_full_name: 'Enter full name',
			enter_email: 'Enter email address',
			country_code: 'Country Code',
			otp: 'One time password',
			resend_otp: 'Resend in {seconds} seconds',
			phone_number: 'Enter 10 digit phone number',
			enter_password: 'Enter password',
			enter_otp: 'Enter OTP',
			firstname: 'John Doe',
			email_address: 'demo@company.com'
		},
		heading: {
			amount_in_wallet: 'Amount in your wallet:',
			balance: 'Balance',
			balance_kwh: 'kWh Balance',
			last_transaction: 'Last Transaction',
			terms_of_service: 'Terms of Service',
			privacy_policy: 'Privacy Policy',
			please_type_the: 'Please type the',
			add_vehicle: 'Add Vehicle',
			my_usage: 'My Usage',
			charging_sessions: 'Charging Sessions',
			upcoming: 'Upcoming',
			past: 'Past',
			cancelled: 'Cancelled',
			facing_issue_charging: 'Facing any issue while charging?',
			terra_wallet: 'Terra Wallet',
			estmate_approx: 'Estimate (Approx)',
			already_have_account: 'Already have an account?',
			signup: 'Sign up',
			dont_have_account: 'Don\'t have an account?',
			login: 'Login',
			forgot_password: 'Forgot Password',
			back_to_login: 'Back to login',
			home: 'Home',
			signup_with: 'Sign up with',
			login_with: 'Login with',
			dashboard: 'Dashboard',
			profile: 'Profile',
			wallet: 'Wallet',
			my_vehicles: 'My Vehicles',
			charging_history: 'Charging History',
			contact_us: 'Contact Us',
			logout: 'Logout',
			map: 'Map',
			history: 'History',
			terra_motors_country: 'Terra Motors India.',
			unpaid_fees: 'Unpaid Fees',
			available_coupons: 'Available Coupons',
			rfid_registrations: 'RFID Registrations',
		},
		list: {
			charging_statuses: {
				charging: 'Charging',
				abandoned: 'Abandoned',
				available: 'Available',
				completed: 'Completed',
				initiated: 'Initiated',
				preparing: 'Preparing'
			}
		},
		button: {
			continue: 'Continue',
			save: 'Save',
			topup: 'Top Up',
			add_money: 'Add Money',
			map_view: 'Map View',
			type_device_id: 'Type Device ID',
			activate: 'Activate',
			resend_otp: 'Resend OTP',
			password: 'Password',
			cancel: 'Cancel',
			update: 'Update',
			logout: 'Logout',
			cancel_goback: 'Cancel, Go Back',
			confirm_delete: 'Confirm Delete',
			continue_on_app: 'I Confirm, I want To Continue With Web App.',
			click_to_call: 'Click here to call us',
			or_dial: 'or dial',
			stop_charging: 'Stop Charging',
			wallet_link: 'Wallet',
			next_disabled: 'Button will be activated, once time or price is selected.',
			next: 'Next',
			start_charging: 'Start Charging',
			verify_otp: 'Verify OTP',
			send_otp: 'Send OTP',
			send_password: 'Send Password',
			create_account: 'Create Account',
			login: 'Login',
			credit: 'Credit/Debit',
			qr: 'QR',
			select: 'Select',
			confirm_country_change: 'Confirm Change',
			enter_new_address: 'Enter a new address',
			add_billing_address: 'Please add your billing address',
			download_invoice: 'Download Invoice',
			generate_invoice: 'Generate Invoice',
			scan_to_checkout: 'Scan to Checkout',
			make_payment: 'Make Payment',
			purchase_kwh:"Purchase kWh",
			apply_now: "Apply Now",
			back_to_home:"Back to home",
			track_shipment:"Track shipment",
			place_order:"Place Order",
			already_requested:"Already Requested",
			apply_coupon: "Apply Coupon"
		},
		generic: {
			or: 'or',
			and: 'and',
			continue_with_google: 'Continue with google',
			agree_terms_conditions: 'By proceeding you agree to our',
			terms_and_conditions: 'Terms & conditions',
			privacy_policy: 'Privacy Policy',
			currency_char: '₹',
			device_id: 'Device ID',
			month: 'Month',
			week: 'Week',
			months: {
				jan: 'Jan',
				feb: 'Feb',
				mar: 'Mar',
				apr: 'Apr',
				may: 'May',
				jun: 'Jun',
				jul: 'Jul',
				aug: 'Aug',
				sep: 'Sep',
				oct: 'Oct',
				nov: 'Nov',
				dec: 'Dec',
			},
			days: {
				mon: 'Mon',
				tue: 'Tue',
				wed: 'Wed',
				thu: 'Thu',
				fri: 'Fri',
				sat: 'Sat',
				sun: 'Sun'
			},
			open24in7: 'Open 24 x 7',
			download_app: 'Download Terra Charge App',
			phone_no: '9902179575',
			available: 'Available',
			dateandtime: 'Date & Time',
			amount: 'Amount',
			kwh: 'kWh',
			kw: 'kW',
			hour: 'Hour',
			minute: 'Minute',
			hours: 'Hours',
			mins: 'Mins',
			seconds: 'seconds',
			update_available:"App update available"
		},
		message: {
			no_internet_wrapper: 'No Internet Connection. Please try again later.',
			not_valid_qr_code: 'Not a valid Terra QR Code',
			you_dont_have_vehicles: 'You don\'t have any saved Vehicles',
			you_dont_have_charging: 'You don\'t have any charging history records',
			loading: 'Loading...',
			no_charging_records: 'No charging Records found.',
			nothing_found_in_upcoming: 'Nothing Found In Upcoming',
			nothing_found_in_past: 'Nothing Found In Past',
			nothing_found_in_cancelled: 'Nothing Found in Cancelled',
			are_you_sure_delete_account: 'Are you sure, you want to delete your account?',
			delete_acccount_warning: 'By deleting your account, you will have to confirm that you will no longer have access to your account, and all of your data will be scheduled for deletion with in next 7 days.',
			error_boundary: 'ErrorBoundary caught an error',
			something_went_wrong: 'Something went wrong.',
			stopping: 'Stopping.....',
			use_this_slider_set_session: 'Use this slider to set your charging session in either Price or Time',
			use_this_slider_time_amount: 'Use this slider to either select the time or amount for which you want the charging session to continue. Charging will auto stop after it reaches the selected time or price.',
			charger_not_found: 'Charger not found',
			loging_out: 'Logging out.....',
			otp_cannot_empty: 'OTP cannot be empty',
			resend_otp_in: 'Resend in',
			registration_successful: 'Registration Successful. Please verify your phone number.',
			verify_inputs: 'Please verify your inputs',
			by_siging_up_agree: 'By Signing up, you agree to our',
			redirecting: 'Redirecting...',
			all_right_reserve: 'All Rights Reserved.',
			confirm_change_country: 'Warning!!! Changing Country',
			change_country_warning: 'You may not be able to login with OTP if you change country. You may not receive OTP.',
			penalty_is_running: 'Penalty is running',
			please_settle_the_fines: 'Please settle the fine before using.',
			outstanding_amount: 'There is an outstanding amount due for overtime parking fines',
			charger_is_starting_up: 'Charger is starting up ...',
			charge_info_group_1_1: 'Please',
			charge_info_group_1_2: 'Lock',
			charge_info_group_1_3: 'your car and',
			charge_info_group_1_4: 'Connect',
			charge_info_group_1_5: 'the charger cable.',
			please_dont_close_the_app: 'Please don’t close the App.',
			get_ready: 'Get Ready!',
			charge_info_group_2_1: 'Please don’t forget to pick up your car',
			charge_info_group_2_2: 'on time to avoid an additional charge of',
			please_pay: 'Please pay within completing your charging session',
			session_is_overtime: 'Session is Overtime !',
			additional_overtime: 'Additional charge of over time',
			balance_not_enough: 'Balance is not enough. Please Top up.',
			no_charger_found:"No Charger Found",
			unable_to_stop_charging:"You are not allowed to stop charging before 1 minute. Please try again.",
			select_address_to_download_invoice:"Please select an address to download the invoice",
			select_address_to_request_rfid:"Please select an address to request the RFID",
			no_coupons_found: "No Coupons Found",
			add_to_wallet: "Add */amount/* to your wallet",
			applied:"Applied",
			coupon_apply_after_session_note: "Note :- Coupon will only be applied when the charging session ends"
		}
	}
};

export default Language;
