import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { AppBar, IconButton, Toolbar, useMediaQuery } from '@mui/material';

// project import
import AppBarStyled from './AppBarStyled';
import HeaderContent from './HeaderContent';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
// assets
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { authHeaders, deleteFromLocalStorage, getCookie, getFromLocalStorage, getGeoCountryCode } from 'common';
import { useEffect, useState } from 'react';
import urls from 'backend_urls';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { User } from 'store/reducers/userSlice';
import { useNavigate, useLocation } from 'react-router-dom';


// ==============================|| MAIN LAYOUT - HEADER ||============================== //

const Header = ({ open, handleDrawerToggle }) => {
  const [isPDPADone, setIsPDPADone] = useState(true)
  const [is_pdpa_page, setPdpaPage] = useState(true)
  const [countryCode, setCountryCode] = useState(null)
  const user = useSelector(state => state.user.data)
  const theme = useTheme();
  const dispatch = useDispatch()
  const matchDownMD = useMediaQuery(theme.breakpoints.down('lg'));
  const location = useLocation();
  const navigate = useNavigate()

  const isLoggedIn = useSelector(state => state.login.data)
  useEffect(() => {
    dispatch(User());
  }, [isLoggedIn]);

  const iconBackColor = theme.palette.navButton.open;
  const iconBackColorOpen = theme.palette.navButton.closed;

  const goBack = () => {
    // window.history.back();
    const matched = document.location.href.match(/\/charger\//)

    if (navigate.length > 1 && !matched) {
      if (location.pathname === "/not-found")
        navigate(-2);
      else
        navigate(-1);
    } else {
      navigate('/home');
    }
  };

  useEffect(() => {
    const setUserCountryCode = async () => {
      if (user?.country) {
        const countryCode = user.country === 'Thailand' ? 'th' : user.country === 'Indonesia' ? 'id' : 'in';
        setCountryCode(countryCode);
      } else {
        const countryCode = (await getGeoCountryCode()) || 'IN';
        setCountryCode(countryCode.toLowerCase());
      }
    };

    setUserCountryCode();
  }, [user]);

  useEffect(() => {
    if (!user?.is_pdpa_done && countryCode == "th") setIsPDPADone(false)
    else {
      setIsPDPADone(true)
    }
  }, [isPDPADone, user, countryCode])

  useEffect(() => {
    if (location.pathname === '/pdpa')
      setPdpaPage(false)
    else
      setPdpaPage(true)
  }, [location])

  // common header
  const mainHeader = (
    <Toolbar>
      {is_pdpa_page &&
        <IconButton
          disableRipple
          aria-label="open drawer"
          onClick={goBack}
          edge="start"
          sx={{ color: 'text.primary', bgcolor: open ? iconBackColorOpen : iconBackColor, ml: { xs: 0, lg: -2 } }}
        >
          <ArrowBackIcon />
        </IconButton>
      }
      <HeaderContent />
      {is_pdpa_page &&
        <IconButton
          disableRipple
          aria-label="open drawer"
          onClick={handleDrawerToggle}
          edge="end"
          sx={{ color: 'text.primary', bgcolor: open ? iconBackColorOpen : iconBackColor, ml: { xs: 1, lg: 2 } }}
        >
          {!open ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        </IconButton>
      }
    </Toolbar>
  );

  // app-bar params
  const appBar = {
    position: 'fixed',
    color: 'inherit',
    elevation: 0,
    sx: {
      borderBottom: `1px solid ${theme.palette.divider}`
      // boxShadow: theme.customShadows.z1
    }
  };

  return (
    <>
      {!matchDownMD ? (
        <AppBarStyled open={open} {...appBar}>
          {mainHeader}
        </AppBarStyled>
      ) : (
        <AppBar {...appBar}>{mainHeader}</AppBar>
      )}
    </>
  );
};

Header.propTypes = {
  open: PropTypes.bool,
  handleDrawerToggle: PropTypes.func
};

export default Header;
