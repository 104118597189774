import {
	Box, Toolbar, useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { deleteFromLocalStorage, getFromLocalStorage, isLoggedIn, isPenaltySessionActive, isSessionActive, toastContainer, whichAppOsIsThis } from 'common';
import BottomBarButtons from 'components/BottomBarButtons';
import DownloadApp from 'components/DownloadApp/DownloadApp';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	Outlet,
	// Link, 
	useLocation,
	useNavigate
} from 'react-router-dom';
import { toast } from 'react-toastify';
import { openDrawer } from 'store/reducers/menuSlice';
import Drawer from './Drawer';
import Header from './Header';
import UpdateApp from 'components/DownloadApp/UpdateApp';


const MainLayout = () => {
	const theme = useTheme();
	const matchDownLG = useMediaQuery(theme.breakpoints.down('lg'));
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { drawerOpen } = useSelector((state) => state.menu);
	const [open, setOpen] = useState(drawerOpen);
	const location = useLocation();
	const [popupMessage] = useState(getFromLocalStorage('toast_message'));
    const user = useSelector(state => state.user.data)
	const [isSessionRunning, setIsSessionRunning] = useState(null);

	const checkSession = async () => {
		if (isLoggedIn()) {
			const PenaltySessionActive = await isPenaltySessionActive();
			setIsSessionRunning(PenaltySessionActive);
		}
	};

	useEffect(() => {
		checkSession();
	}, []);

	const { pathname } = location;

	const handleDrawerToggle = () => {
		setOpen(!open);
		dispatch(openDrawer({ drawerOpen: !open }));
	};

	const navigateToQRScanner = () => {
		let app = whichAppOsIsThis();
		if (app === 'android') window.location.href = `https://com.terracharge.dione.android/####qr-scanner####`;
		else if (app === 'ios') window.location.href = `https://com.terracharge.hyperion.ios/####qr-scanner####`;
		else navigate('/qr-scanner');
	};

	useEffect(() => {
		if (pathname === '/') {
			navigate('/home');
		}
		if (isLoggedIn() && pathname == '/onboarding/phone') navigate('/my-account');
		if (!isLoggedIn() && pathname == '/onboarding/phone') setOpen(!matchDownLG);
		if (isLoggedIn() && pathname == '/pdpa') setOpen(false);
		dispatch(openDrawer({ drawerOpen: !matchDownLG }));

	}, [matchDownLG]);

	useEffect(() => {
		if (open !== drawerOpen) setOpen(drawerOpen);
	}, [drawerOpen]);

	useEffect(() => {
		let toastMessage = JSON.parse(popupMessage);
		if (popupMessage !== undefined && popupMessage !== false) {
			if (toastMessage.type === 'error') toast.error(toastMessage.message);
			if (toastMessage.type === 'success') toast.success(toastMessage.message);
			if (toastMessage.type === 'info') toast.info(toastMessage.message);
			if (toastMessage.type === 'warn') toast.warn(toastMessage.message);
			deleteFromLocalStorage('toast_message');
		}
	}, []);

	const shouldShowBottomBar = () => {
		return pathname !== '/privacy-policy' &&
			pathname !== '/contact-us' &&
			!(pathname.includes('wallet') && !isLoggedIn());
	};

	return (
		<>
			{toastContainer()}
			<Box
				sx={{
					display: 'flex',
					overflow: 'auto',
					pb: pathname !== '/home' ? pathname === '/wallet' ? 0 : 10 : 0,
					width: '100%',
					background: isLoggedIn() && pathname == '/wallet' ? theme.palette.wallet.bg : theme.palette.background.paper
				}}
			>
				{!(pathname.includes('wallet') && !isLoggedIn()) &&
					<>
						<Header open={open} handleDrawerToggle={handleDrawerToggle} />
						<Drawer open={open} handleDrawerToggle={handleDrawerToggle} />
					</>
				}
				<Box
					component="main"
					sx={{
						width: '100%',
						flexGrow: 1,
					}}
					id="mybo"
				>
					<Toolbar />
					<Outlet />
				</Box>
			</Box>
			{shouldShowBottomBar() && <BottomBarButtons qr={navigateToQRScanner} open={open} />}
			<DownloadApp />
			{isLoggedIn() && user.country_short_code !=='IN' && !(user.app_version_ios || user.app_version_android) && isSessionRunning && <UpdateApp />}
		</>
	);
};

export default MainLayout;
