// third-party
import { combineReducers } from 'redux';
import menuSlice from './menuSlice';
import loginSlice from './loginSlice';
import otpSlice from './otpSlice';
import verifyOTPSlice from './verifyOTPSlice';
import userSlice from './userSlice';
import activeCountrySlice from './activeCountrySlice';
import appliedCouponSlice from './appliedCouponSlice';
import selectedChargeValueSlcie from './selectedChargeValueSlcie';



// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = combineReducers({
    menu: menuSlice,
    login: loginSlice,
    otp: otpSlice,
    verifyOtp: verifyOTPSlice,
    user: userSlice,
    activeCountry: activeCountrySlice,
    appliedCoupon: appliedCouponSlice,
    selectedValue: selectedChargeValueSlcie
});

export default reducers;
